@keyframes slidein {
  from {
    height: 0;
    transform: scale(0);
  }

  to {
    height: 64px;
    transform: scale(1);
  }
}

@keyframes wiggle {
  from {
    transform: translateX(-10px);
  }

  to {
    transform: translateX(0);
  }
}

a {
  color: #add8e6;
}

body {
  color: #fff;
  text-align: center;
  height: 100vh;
  background-color: #181818;
  flex-direction: column;
  margin: 0;
  font-family: Helvetica, sans-serif;
  display: flex;
}

main {
  max-width: 500px;
  margin: auto;
  padding: 10px;
}

button, select, input {
  color: #fff;
  background: none;
  border: 2px solid #fff;
  padding: 10px;
  font-weight: bold;
  transition: all .25s;
}

button:focus-visible, select:focus-visible, input:focus-visible {
  border-style: dashed;
  outline: none;
}

input:focus-visible {
  outline: none;
}

option {
  background: #181818;
}

button:disabled {
  cursor: not-allowed;
  background: gray;
}

section {
  margin: 20px 0;
}

#board {
  flex-direction: column;
  font-family: monospace;
  font-size: 32px;
  font-weight: bold;
  display: flex;
}

#board div {
  animation: .25s slidein;
}

footer {
  text-align: left;
  margin-left: 15px;
  padding-bottom: 15px;
}

#board span {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: all .25s;
  display: inline-flex;
}

#buttons {
  margin-top: 5px;
}

#buttons button {
  width: 35px;
  height: 35px;
  touch-action: manipulation;
  padding: 0;
}

#submit {
  margin-top: 25px;
}

#submit:disabled:after {
  content: " (Invalid Score)";
}

#game-over {
  width: 250px;
  border: 2px solid #fff;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

#game-over .shown {
  max-height: 160px;
}

#tutorial {
  pointer-events: none;
  font-weight: bold;
  transition: all .25s;
  animation: .5s ease-in-out infinite alternate wiggle;
  position: absolute;
}

#tutorial-wrapper {
  position: relative;
  top: 10px;
  left: -160px;
}

#tooltip {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
  position: relative;
  top: -3px;
}

#tooltip:focus-visible {
  border-style: dashed;
  outline: none;
}

#high-contrast-mode {
  transition: none;
}

#high-contrast-mode:focus-visible {
  outline: 1px dashed #fff !important;
}

.popup {
  text-align: left;
  background-color: #181818;
  border: 2px solid #fff;
  padding: 15px;
  position: fixed;
  top: 10px;
  left: 10px;
}

.popup h2, #tooltip-popup p {
  margin: 0;
}

#keyboard {
  margin-bottom: 20px;
}

#keyboard span {
  transition: all .25s;
}

#info-button {
  color: #2785fd;
}

#settings-button {
  cursor: pointer;
}

#share {
  margin-bottom: 15px;
}

#score-wrapper {
  font-size: larger;
}

#message {
  font-size: medium;
}

#why-did-i-lose-popup-button {
  margin-top: 15px;
}

#why-did-i-lose-input {
  width: 50px;
}

#why-did-i-lose-output {
  max-width: 250px;
  font-weight: bold;
}

.thought-bubble-container {
  width: 160px;
  text-align: left;
  margin: auto;
  font-size: 25px;
}

.thought-bubble-container > div {
  color: #000;
  background-color: #fff;
  border-radius: 25px;
  margin-bottom: 5px;
  font-weight: bold;
}

.thought-bubble-container .main {
  width: 160px;
  height: 75px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  display: flex;
}

.thought-bubble-container .medium {
  width: 35px;
  height: 15px;
  margin-left: 20px;
}

.thought-bubble-container .small {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.hidden, .shown {
  transition: all .25s;
  overflow: hidden;
}

.hidden {
  max-height: 0;
  transform: scale(0);
  padding: 0 !important;
}

.shown {
  transform: scale(1);
}

.yellow {
  background-color: #919101 !important;
}

.green {
  background-color: #067306 !important;
}

.high-contrast .yellow {
  background-color: #0082a4 !important;
}

.high-contrast .green {
  background-color: #bd3502 !important;
}

.letter {
  color: #fff;
  font-weight: bold;
}

.letter-used {
  color: gray;
  font-variant-numeric: tabular-nums;
}

.letter-yellow {
  color: #eaea54;
}

.letter-green {
  color: #3fcb3f;
}

.high-contrast .letter-yellow {
  color: #08efe2;
}

.high-contrast .letter-green {
  color: #f3500f;
}

svg {
  transition: all .35s;
  position: fixed;
  bottom: 0;
  right: 0;
}

svg:hover {
  transform: scale(1.1);
}

.eye-background {
  filter: url("#glow") blur(1px);
}

/*# sourceMappingURL=index.202eaf27.css.map */
